import React, { Component } from 'react';
import NavTabs from "./NavTabs";
import "./Home.css";
import Background from "../img/profast.png";
import * as backendApi from '../../services/api';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      base64: null,
      email: "",
      barcodes: [],
      count: 0,
      clientList: [],
      barcodesCase: {
      }
    };

  }

  async componentDidMount() {
    this.props.loading(true);
    if (this.props.isAuthenticated) {
      await backendApi.getColumnsData();
      await backendApi.getUserData();

      this.createInterval();
    } else {
      this.props.loading(false);
      if (this.interval) {
        clearInterval(this.interval);
      }
      return;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createInterval() {
    this.interval = setInterval(() => {
      this.getData();
    }, 1000);
  }

  getData = () => {
    const clientList = JSON.parse(localStorage.getItem("clients"));
    const myData = JSON.parse(localStorage.getItem("myData"));
    this.setState({ email: myData.email, clientList: clientList });

    if (clientList !== null && myData !== null ) {
      clearInterval(this.interval);

      let isClient = false;
      let userRoles = {};
      let clientLimit = {};
      clientLimit[myData.client] = 1;

      myData.userRoles.forEach(async (item, i) => {
        userRoles[item.name] = 1;
        if (item.name === "Management") {
          this.props.admin(true);
        } else if (item.name === "QuickView") {
          isClient = true;
        }

      });

      this.props.setUserRoles(userRoles);
      this.props.setClientLimit(clientLimit);

      if (isClient) {
        this.props.setCustList([{name: myData.client}]);
        this.props.history("/records");
      } else {
        this.props.setCustList(clientList);
        this.props.loading(false);
      }
    }

  }

  render() {

    return (
      <div className="Home">

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <img src={Background} alt="img" className="background-home-img"/>

      </div>
    );
  }
}
