import React, { Component } from 'react';
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { ToastContainer, toast, Flip } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavTabs from "../home/NavTabs";
import moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

import ThFilter from "./ThFilter";

import "./Report.css";

import * as backendApi from '../../services/api';

export default class ReportOther extends Component {

  notifyError = () => toast.error('Too much data to fetch. Try select using 3 or 6 month intervals.', {delay: 0, containerId: 'error', autoClose: 2000});

  constructor(props) {
    super(props);

    this.state = {
      reportOther: [],
      clients: [],
      courts: [],
      process: [],
      sheriffs: [],
      status: [],
      filterArray: [],
      filterLookup: {},
      dateSelections: [],
      clientName: "",
      timeframe: "",
      month3Check: false,
      month6Check: false,
      lastKeyRef: "",
      client: "",
      outstandingReports: false
    };
  }

  componentDidMount() {
    const myData = JSON.parse(localStorage.getItem("myData"));

    const clients = JSON.parse(localStorage.getItem('clients'));
    const courts = JSON.parse(localStorage.getItem('courts'));
    const process = JSON.parse(localStorage.getItem('process'));
    const sheriffs = JSON.parse(localStorage.getItem('sheriffs'));
    const status = JSON.parse(localStorage.getItem('statuses'));

    var isClient = false;
    myData.userRoles.forEach((item, i) => {
      if (item.name === "QuickView") {
        isClient = true;
      }
    });

    if (isClient) {
      for (var c = 0; c < clients.length; c++) {
        let client = clients[c].name;

        if (client !== myData.client) {
          clients.splice(c, 1);
          c--;
        }
      }
    }

    this.setState({
      clients: clients,
      courts: courts,
      process: process,
      sheriffs: sheriffs,
      status: status,
      dateSelections: [{
        name: "Last Week"
      },{
        name: "Last Month"
      },{
        name: "Last Year"
      }]
    });

    if (isClient) {
      this.onClientSelected(myData.client);
    }
  }

  onClientSelected = async (client) => {
    this.setState({
      reportOther: [],
      unfilteredRows: [],
      filterArray: [],
      filterLookup: {},
      client: client,
      keyRefList: []
    });

    let keyRefList = [];
    if (client !== "") {
      this.props.loading(true);
      try {
        let allItems = [];

        if (this.state.outstandingReports) {
          allItems = await backendApi.getReportList("isOther", client, this.state.timeframe, "outstandingReports");
        } else {
          allItems = await backendApi.getReportList("isOther", client, this.state.timeframe, "");
        }

        let unfilteredRows = [];
        let reports = [];

        allItems.forEach((item) => {
          if (item.lastKeyRef) {
            this.setState({ lastKeyRef: item.lastKeyRef });
            keyRefList.push({ lastKeyRef: item.lastKeyRef });
          } else {

            if (item[0].toSheriff) {
              item[0].toSheriff = item[0].toSheriff === "" || item[0].toSheriff === null ? null : item[0].toSheriff;
            } else {
              item[0].toSheriff = null;
            }

            reports.push(item[0]);
            unfilteredRows.push(item[0]);
          }
        });

        this.setState({
          reportOther: reports,
          unfilteredRows: unfilteredRows,
          clientName: client,
          keyRefList: keyRefList
        });

        this.props.loading(false);
        this.setState({ outstandingReports: false });
      } catch (e) {
        this.props.loading(false);
        this.notifyError();
      }
    } else {
      this.setState({ month3Check: false, month6Check: false, timeframe: "" });
    }
  }

  getOustandingReports = async () => {
    let filteredItems = [];
    let rows = this.state.unfilteredRows;
    let sheriffReturns = await backendApi.getReportList("isSheriff", this.state.client, this.state.timeframe, "");
    let sheriffRefs = {};

    sheriffReturns.forEach((ret) => {
      const refNumber = ret[0].refNumber;
      if (!(refNumber in sheriffRefs)) {
        sheriffRefs[refNumber] = 1;
      }
    });

    rows.forEach((item, i) => {

      if (!(item.refNumber in sheriffRefs)) {
        if (item.toSheriff !== "" && item.toSheriff !== null) {
          filteredItems.push(item);
        }
      }

    });

    this.setState({
      reportOther: filteredItems
    }, () => {
      if (filteredItems.length > 0) {
        this.saveAsExcel();
      }

      setTimeout(() => {
        this.setState({
          reportOther: rows
        });
      }, 7000);
    });

  }

  filterDate = (value, field, filteredItems) => {
    let lastWeekStart = moment().subtract(1, "weeks").startOf("week").format("DD/MM/YYYY");
    let lastWeekEnd = moment().subtract(1, "weeks").endOf("week").format("DD/MM/YYYY");
    let lastMonthStart = moment().subtract(1, "months").startOf("month").format("DD/MM/YYYY");
    let lastMonthEnd = moment().subtract(1, "months").endOf("month").format("DD/MM/YYYY");
    let lastYearStart = moment().subtract(1, "years").startOf("year").format("DD/MM/YYYY");
    let lastYearEnd = moment().subtract(1, "years").endOf("year").format("DD/MM/YYYY");

    let rows = [];

    let dateToClient = "";
    let dateCreatedOn = "";
    let dateStatus = "";

    switch (value.name) {

      case "Last Week":
        console.log(lastWeekStart + " : " + lastWeekEnd);

        filteredItems.forEach((row, i) => {
          let isInRange = false;

          dateToClient = row.toClient.substr(6,4) + "-" + row.toClient.substr(3,2) + "-" + row.toClient.substr(0,2);
          dateCreatedOn = row.createdOn.substr(6,4) + "-" + row.createdOn.substr(3,2) + "-" + row.createdOn.substr(0,2);
          dateStatus = row.statusDate.substr(6,4) + "-" + row.statusDate.substr(3,2) + "-" + row.statusDate.substr(0,2);

          switch (field) {
            case "dateCreate":
              isInRange = moment(dateCreatedOn).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateStatus":
              isInRange = moment(dateStatus).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateClient":
              isInRange = moment(dateToClient).isBetween(lastWeekStart, lastWeekEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;
            default:
          }
        });

        break;

      case "Last Month":
        console.log(lastMonthStart + " : " + lastMonthEnd);

        filteredItems.forEach((row, i) => {
          let isInRange = false;

          dateToClient = row.toClient.substr(6,4) + "-" + row.toClient.substr(3,2) + "-" + row.toClient.substr(0,2);
          dateCreatedOn = row.createdOn.substr(6,4) + "-" + row.createdOn.substr(3,2) + "-" + row.createdOn.substr(0,2);
          dateStatus = row.statusDate.substr(6,4) + "-" + row.statusDate.substr(3,2) + "-" + row.statusDate.substr(0,2);

          switch (field) {
            case "dateCreate":
              isInRange = moment(dateCreatedOn).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateStatus":
              isInRange = moment(dateStatus).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateClient":
              isInRange = moment(dateToClient).isBetween(lastMonthStart, lastMonthEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;
            default:
          }
        });
        break;

      case "Last Year":
        console.log(lastYearStart + " : " + lastYearEnd);

        filteredItems.forEach((row, i) => {
          let isInRange = false;

          dateToClient = row.toClient.substr(6,4) + "-" + row.toClient.substr(3,2) + "-" + row.toClient.substr(0,2);
          dateCreatedOn = row.createdOn.substr(6,4) + "-" + row.createdOn.substr(3,2) + "-" + row.createdOn.substr(0,2);
          dateStatus = row.statusDate.substr(6,4) + "-" + row.statusDate.substr(3,2) + "-" + row.statusDate.substr(0,2);

          switch (field) {
            case "dateCreate":
              isInRange = moment(dateCreatedOn).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateStatus":
              isInRange = moment(dateStatus).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;

            case "dateClient":
              isInRange = moment(dateToClient).isBetween(lastYearStart, lastYearEnd);
              if (isInRange) {
                rows.push(row);
              }
              break;
            default:
          }
        });
        break;
      default:

        if (value.name.length > 3) {
          filteredItems.forEach((row, i) => {
            switch (field) {
              case "dateCreate":
                if (row.createdOn !== null && row.createdOn.includes(value.name)) {
                  rows.push(row);
                }
                break;

              case "dateStatus":
                if (row.statusDate !== null && row.statusDate.includes(value.name)) {
                  rows.push(row);
                }
                break;

              case "dateClient":
                if (row.toClient !== null && row.toClient.includes(value.name)) {
                  rows.push(row);
                }
                break;
              default:
            }
          });
        } else {
          rows = filteredItems;
        }
    }

    return rows;
  }

  filterDropdowns = (value, field) => {

    value = value.toUpperCase();

    let clients = JSON.parse(localStorage.getItem('clients'));
    let courts = JSON.parse(localStorage.getItem('courts'));
    let process = JSON.parse(localStorage.getItem('process'));
    let status = JSON.parse(localStorage.getItem('statuses'));
    let sheriffs = JSON.parse(localStorage.getItem('sheriffs'));

    switch (field) {
      case "client":
        clients = clients.filter(item => item.name.includes(value));
        break;
      case "court":
        courts = courts.filter(item => item.name.includes(value));
        break;
      case "process":
        process = process.filter(item => item.name.includes(value));
        break;
      case "sheriff":
        sheriffs = sheriffs.filter(item => item.name.includes(value));
        break;
      case "status":
        status = status.filter(item => item.name.includes(value));
        break;
      default:

    }

    this.setState({
      clients: clients,
      courts: courts,
      process: process,
      sheriffs: sheriffs,
      status: status
    });
  }

  filterEntries = (filteredItems, filter, fromInput) => {
    let rows = [];
    const column = filter.columnName;

    filteredItems.forEach((row, i) => {

      switch (column) {
        case "refNumber":
        case "caseNumber":
        case "batchNumber":

          for (var val in filter.values) {
            if (row[column].includes(val)) {
              rows.push(row);
            }
          }

          break;
        default:
          if (row[column] in filter.values) {
            rows.push(row);
          }
      }

    });

    return rows;
  }

  onCheckSelected = (e, item, field, fromInput) => {

    let filterArray = this.state.filterArray;
    let filteredItems = this.state.unfilteredRows;
    let filterLookup = this.state.filterLookup;

    if (!(field in filterLookup)) {

      let obj = {
        columnName: field, //client, process, count etc
        values: {}
      }

      obj.values[item.name] = 1;

      filterLookup[field] = 1;

      filterArray.push(obj);
    } else if (!e.target.checked && !fromInput && !field.includes("date")) {

      for (var i = 0; i < filterArray.length; i++) {
        if (filterArray[i].columnName === field) {
          delete filterArray[i].values[item.name];
        }
        let removeFilter = true;
        for (var prop in filterArray[i].values) {
          if (filterArray[i].values.hasOwnProperty(prop)) {
            removeFilter = false;
          }
        }
        if (removeFilter) {
          filterArray.splice(i, 1);
          i--;
          delete filterLookup[field];
        }

      }

    } else {
      filterArray.forEach((filter, i) => {

        if (fromInput && filter.columnName === field) {

          for (var val in filter.values) {
            delete filter.values[val];
            filter.values[item.name] = 1;
            break;
          }

        } else if (filter.columnName === field) {
          filter.values[item.name] = 1;
        }

      });
    }

    if (filterArray.length !== 0) {

      filterArray.forEach((filter, i) => {

        if (filter.columnName.includes("date") || filter.columnName.includes("statusDate")) {

          for (var prop in filter.values) {
              item = { name: prop };
          }

          filteredItems = this.filterDate(item, filter.columnName, filteredItems);
        } else {
          filteredItems = this.filterEntries(filteredItems, filter, fromInput);
        }

      });
    }

    this.setState({
      reportOther: filteredItems,
      filterArray: filterArray,
      filterLookup: filterLookup
    });
  }

  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  saveAsExcel = async () => {
    const data = this.state.reportOther.map(row => ({
        "Created On": row.createdOn,
        "Client": row.client,
        "Process": row.process,
        "Court": row.court,
        "Sheriff": row.sheriff,
        "Defendant": row.defendant,
        "Ref Number": row.refNumber,
        "Case Number": row.caseNumber,
        "Status": row.otherStatus,
        "To Sheriff": row.toSheriff,
        "Status Date": row.statusDate,
        "Batch": row.batchNumber,
        "To Client": row.toClient
    }));

    const headers = Object.keys(data[0]);

    // Detect columns that contain valid date strings
    const dateColumns = headers.filter(header =>
        data.some(row => row[header] && /^\d{2}\/\d{2}\/\d{4}$/.test(row[header]))
    );

    XlsxPopulate.fromBlankAsync().then(async workbook => {
        const sheet = workbook.sheet(0);
        sheet.cell("A1").value(this.getSheetData(data, headers));
        sheet.row(1).style("bold", true);

        // Apply date formatting dynamically
        sheet._rows.forEach((row, rowIndex) => {
            if (rowIndex > 0) { // Skip header row
                headers.forEach((header, colIndex) => {
                    const cell = row.cell(colIndex + 1);
                    const value = cell.value();
                    if (dateColumns.includes(header) && typeof value === "string" && /^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
                        const [day, month, year] = value.split("/").map(num => parseInt(num, 10));
                        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                            cell.value(new Date(year, month - 1, day)).style("numberFormat", "dd/MM/yyyy");
                        }
                    }
                });
            }
        });

        // Auto column width adjustments
        headers.forEach((header, colIndex) => {
            const columnLetter = String.fromCharCode(65 + colIndex);
            const maxWidth = Math.max(...data.map(row => (row[header]?.toString().length || 0)), header.length);
            sheet.column(columnLetter).width(Math.min(maxWidth + 2, 25));
        });

        // Apply border to header row
        const endColumn = String.fromCharCode(64 + headers.length);
        sheet.range(`A1:${endColumn}1`).style("border", true);

        return workbook.outputAsync().then(res => saveAs(res, "OtherReport.xlsx"));
    });
  };

  exportPDF = () => {
    const unit = "px";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Other Report";

    const headers = [[
      "Client", "Process", "Court", "Sheriff", "Defendant", "Ref Number", "Case Number",
      "Status", "Status Date", "Batch", "To Client"
    ]];

    let tableData = this.state.reportOther;

    tableData.forEach((item, i) => {
      item.statusDate = item.statusDate !== "" ? item.statusDate : "";
      item.toClient = item.toClient !== "" ? item.toClient : "";
    });

    const data = tableData.map(elt=> [
      elt.client, elt.process, elt.court, elt.sheriff, elt.refNumber, elt.caseNumber,
      elt.otherStatus, elt.statusDate, elt.batchNumber,
      elt.toClient
    ]);

    let content = {
      startY: 40,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 20);
    doc.autoTable(content);
    doc.save("OtherReport.pdf")
  }

  onMonthsSelected = (selected, months) => {
    this.setState({ timeframe: months, client: "" });

    if (selected && months === "3months") {
      this.setState({ month3Check: true });
      this.setState({ month6Check: false });

    } else if (selected && months === "6months") {
      this.setState({ month6Check: true });
      this.setState({ month3Check: false });

    } else if (!selected && months === "3months") {
      this.setState({ month3Check: false });
      this.setState({ month6Check: true });

    } else if (!selected && months === "6months") {
      this.setState({ month6Check: false });
      this.setState({ month3Check: true });

    }
  }

  render() {
    return(
      <div className="Summons">

        <ToastContainer enableMultiContainer containerId={'error'} transition={Flip} position={toast.POSITION.TOP_CENTER} />

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <div className="table-container">
          <div className="other-heading-report">

            {"QuickView" in this.props.roles ?

              <p style={{position: "absolute", left: "2rem", fontSize: "1.5rem"}}>
                {this.state.clientName}
              </p> :

              <div className="report-select-client">
                <span>Select Client: </span>
                <Form.Control as="select" custom value={this.state.client}
                  onChange={e => this.onClientSelected(e.target.value)}>

                  {this.state.clients.map((client, index) => (
                    <option key={index} >{client.name}</option>
                  ))}

                </Form.Control>

              </div>
            }

            <div>
              <h3>OTHER REPORT</h3>
            </div>

            <Button variant="primary" onClick={this.getOustandingReports} className="outstanding-button"
              style={{ display: this.state.client === "THIPA INCORPORATED" ? '' : 'none'}}>
              Outstanding Sheriff Reports
            </Button>
            <FaRegFileExcel className="print-excel-button" onClick={this.saveAsExcel} />
            <FaRegFilePdf className="print-pdf-button" onClick={this.exportPDF} />
          </div>

          <Table id="reportOther" striped bordered hover size="sm" responsive="sm" className="report-table sheriff-report-table">
            <thead>
              <tr>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Date Created</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateCreate" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Client</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.clients}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDropdowns} field="client" />
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Process</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.process}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="process"/>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Court</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.courts}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="court"/>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Sheriff</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.sheriffs}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="sheriff"/>
                  </Dropdown>
                </th>

                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Defendant</Dropdown.Toggle>
                    <Dropdown.Menu className="report-filter-dropdown-menu">
                      <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "defendant", true)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </th>

                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Ref Number</Dropdown.Toggle>
                    <Dropdown.Menu className="report-filter-dropdown-menu">
                      <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "refNumber", true)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Case Number</Dropdown.Toggle>
                    <Dropdown.Menu className="report-filter-dropdown-menu">
                      <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "caseNumber", true)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>Status</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.status}
                      onCheckSelected={this.onCheckSelected} filterDropdowns={this.filterDropdowns} field="otherStatus"/>
                  </Dropdown>
                </th>

                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>To Sheriff</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="toSheriff" />
                  </Dropdown>
                </th>

                { this.state.client === "THIPA INCORPORATED" ? "" :
                  <th>
                    <Dropdown className="report-filter-dropdown">
                      <Dropdown.Toggle variant="" split>From Sheriff</Dropdown.Toggle>
                      <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                        onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateStatus" />
                    </Dropdown>
                  </th>
                }
                { this.state.client === "THIPA INCORPORATED" ? "" :
                  <th>
                    <Dropdown className="report-filter-dropdown">
                      <Dropdown.Toggle variant="" split>Batch</Dropdown.Toggle>
                      <Dropdown.Menu className="report-filter-dropdown-menu">
                        <Form.Control id="searchInput" type="text" onChange={e => this.onCheckSelected(e, {name: e.target.value}, "batchNumber", true)} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                }
                <th>
                  <Dropdown className="report-filter-dropdown">
                    <Dropdown.Toggle variant="" split>To Client</Dropdown.Toggle>
                    <ThFilter onFilter={this.onFilter} list={this.state.dateSelections}
                      onCheckSelected={this.onCheckSelected} filterDate={this.filterDate} field="dateClient" />
                  </Dropdown>
                </th>
              </tr>
            </thead>
            <tbody>

              {this.state.reportOther.map((row, index) => (
                <tr key={index}>

                  <td>{row.createdOn}</td>
                  <td>{row.client}</td>
                  <td>{row.process}</td>
                  <td>{row.court}</td>
                  <td>{row.sheriff}</td>
                  <td>{row.refNumber}</td>
                  <td>{row.caseNumber}</td>
                  <td>{row.otherStatus}</td>
                  <td>{row.toSheriff}</td>
                  { this.state.client === "THIPA INCORPORATED" ? "" :
                    <td>{row.statusDate}</td>
                  }
                  { this.state.client === "THIPA INCORPORATED" ? "" :
                    <td>{row.batchNumber}</td>
                  }
                  <td>{row.toClient}</td>

                </tr>
              ))}

            </tbody>
          </Table>

        </div>

      </div>
    )
  }
}
