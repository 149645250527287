import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast, Flip } from 'react-toastify';
import { Auth } from "aws-amplify";

import Clients from "./Clients";
import Courts from "./Courts";
import Plaintiffs from "./Plaintiffs";
import Process from "./Process";
import Sheriffs from "./Sheriffs";
import Statuses from "./Statuses";
import Columns from "./Columns";
import Users from "./Users";

import NavTabs from "../home/NavTabs";
import "./Management.css";
import * as backendApi from '../../services/api';

export default class Management extends Component {

  notifySave = () => toast.success('Entries Saved', {delay: 0, containerId: 'success', autoClose: 2000});
  notifyError = () => toast.error('Error during save', {delay: 0, containerId: 'error', autoClose: 2000});

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.getAllConfigData();

    this.setState({ showClients: true });
  }

  getAllConfigData = () => {
    const clients = JSON.parse(localStorage.getItem('clients'));
    const summonsColumns = JSON.parse(localStorage.getItem('summonsColumns'));
    const sheriffColumns = JSON.parse(localStorage.getItem('sheriffColumns'));
    const judgeColumns = JSON.parse(localStorage.getItem('judgeColumns'));
    const otherColumns = JSON.parse(localStorage.getItem('otherColumns'));
    const courts = JSON.parse(localStorage.getItem('courts'));
    const plaintiffs = JSON.parse(localStorage.getItem('plaintiffs'));
    const process = JSON.parse(localStorage.getItem('process'));
    const sheriffs = JSON.parse(localStorage.getItem('sheriffs'));
    const statuses = JSON.parse(localStorage.getItem('statuses'));

    this.setState({
      clients: clients,
      summonsColumns: summonsColumns,
      sheriffColumns: sheriffColumns,
      judgeColumns: judgeColumns,
      otherColumns: otherColumns,
      courts: courts,
      plaintiffs: plaintiffs,
      process: process,
      sheriffs: sheriffs,
      statuses: statuses,
      newUsers: [],
      users: [],
      allItems: []
    }, () => {
      this.getUserInDB();
    });

  }

  getUserInDB = async () => {
    const users = await backendApi.getAllUsers();
    this.setState({ users: users });
  }

  sendUserDetails = (index, row) => {
    //SEND EMAIL TO USER WITH USERNAME AND PASSWORD

    backendApi.sendEmail(row);
  }

  onAddEntry = (what) => {
    switch (what) {
      case "clients":
        let clients = this.state.clients;
        clients.splice(1, 0, { id: clients.length, active: true, name: "" });
        this.setState({ clients: clients });

        break;

      case "columns":

        if (this.state.showColSummons) {
          let summonsColumns = this.state.summonsColumns;
          summonsColumns.splice(0, 0, { id: summonsColumns.length + 1, active: true, name: "" });
          this.setState({ summonsColumns: summonsColumns });
        } else if (this.state.showColSheriff) {
          let sheriffColumns = this.state.sheriffColumns;
          sheriffColumns.splice(0, 0, { id: sheriffColumns.length + 1, active: true, name: "" });
          this.setState({ sheriffColumns: sheriffColumns });
        } else if (this.state.showColJudge) {
          let judgeColumns = this.state.judgeColumns;
          judgeColumns.splice(0, 0, { id: judgeColumns.length + 1, active: true, name: "" });
          this.setState({ judgeColumns: judgeColumns });
        } else if (this.state.showColOther) {
          let otherColumns = this.state.otherColumns;
          otherColumns.splice(0, 0, { id: otherColumns.length + 1, active: true, name: "" });
          this.setState({ otherColumns: otherColumns });
        }

        break;

      case "courts":
        let courts = this.state.courts;
        courts.splice(1, 0, { id: courts.length, active: true, name: "" });
        this.setState({ courts: courts });
        break;

      case "plaintiffs":
        let plaintiffs = this.state.plaintiffs;
        plaintiffs.splice(1, 0, { id: plaintiffs.length, active: true, name: "" });
        this.setState({ plaintiffs: plaintiffs });
        break;

      case "process":
        let process = this.state.process;
        process.splice(1, 0, { id: process.length, active: true, name: "" });
        this.setState({ process: process });
        break;

      case "sheriffs":
        let sheriffs = this.state.sheriffs;
        sheriffs.splice(1, 0, { id: sheriffs.length, active: true, name: "" });
        this.setState({ sheriffs: sheriffs });
        break;

      case "statuses":
        let statuses = this.state.statuses;
        statuses.splice(1, 0, { id: statuses.length, active: true, name: "" });
        this.setState({ statuses: statuses });
        break;

      case "users":
        let newUsers = this.state.newUsers;
        newUsers.splice(0, 0, {
          id: newUsers.length,
          status: "Active",
          client: "",
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          printLoc: "",
          userRoles: [],
          roleNames: ""
        });
        this.setState({ newUsers: newUsers });

        break;
      default:

    }
  }

  onChanged = (index, value, whatChanged) => {

    switch (whatChanged) {
      case "clients":
        this.setState({ clientsChanged: true });
        if (value === "delete") {
          let clients = this.state.clients;
          clients.splice(index, 1);
          this.setState({ clients: clients });
        } else {
          this.setState(state => state.clients[index].name = value.toUpperCase());
        }
        break;

      case "courts":
        this.setState({ courtsChanged: true });
        if (value === "delete") {
          let courts = this.state.courts;
          courts.splice(index, 1);
          this.setState({ courts: courts });
        } else {
          this.setState(state => state.courts[index].name = value.toUpperCase());
        }
        break;

      case "plaintiffs":
        this.setState({ plaintiffsChanged: true });
        if (value === "delete") {
          let plaintiffs = this.state.plaintiffs;
          plaintiffs.splice(index, 1);
          this.setState({ plaintiffs: plaintiffs });
        } else {
          this.setState(state => state.plaintiffs[index].name = value.toUpperCase());
        }
        break;

      case "process":
        this.setState({ processChanged: true });
        if (value === "delete") {
          let process = this.state.process;
          process.splice(index, 1);
          this.setState({ process: process });
        } else if (value === "on" || value === "off") {
          value = value === "on" ? true : false;
          this.setState(state => state.process[index].isJudgement = value);
        } else {
          this.setState(state => state.process[index].name = value.toUpperCase());
        }
        break;

      case "sheriffs":
        this.setState({ sheriffsChanged: true });
        if (value === "delete") {
          let sheriffs = this.state.sheriffs;
          sheriffs.splice(index, 1);
          this.setState({ sheriffs: sheriffs });
        } else {
          this.setState(state => state.sheriffs[index].name = value.toUpperCase());
        }
        break;

      case "statuses":
        this.setState({ statusesChanged: true });
        if (value === "delete") {
          let statuses = this.state.statuses;
          statuses.splice(index, 1);
          this.setState({ statuses: statuses });
        } else {
          this.setState(state => state.statuses[index].name = value.toUpperCase());
        }
        break;

      case "users":
        this.setState({ usersChanged: true });

        if (value.includes("delete")) {
          this.setState({ newUsers: [], usersChanged: false });
        } else {

          if (this.state.newUsers.length > 0) {

            if (value.includes("name")) {
              value = value.replace("name", "");
              value = value.split(" ");
              this.setState(state => state.newUsers[index].firstName = value[0]);
              this.setState(state => state.newUsers[index].lastName = value[1] === undefined ? "" : value[1]);
            } else if (value.includes("print")) {
              value = value.replace("print", "");
              this.setState(state => state.newUsers[index].printLocation = value);
            } else if (value.includes("email")) {
              value = value.replace("email", "");
              this.setState(state => state.newUsers[index].email = value);
            } else if (value.includes("pass")) {
              value = value.replace("pass", "");
              this.setState(state => state.newUsers[index].password = value);
            } else if (value.includes("client")) {
              value = value.replace("client", "");
              this.setState(state => state.newUsers[index].client = value);
            } else {
              this.setState(state => state.newUsers[index].roleNames += value + ", ");
              this.setState(state => state.newUsers[index].userRoles.push({ name: value }));
            }
            this.setState(state => state.newUsers[index].userStatus = "Active");
          } else {
            value = value === "Active" ? "Disabled" : "Active";
            this.setState(state => state.users[index].userStatus = value);
          }
        }

        break;
      default:
        this.setState({ columnsChanged: true });
        if (value === "delete") {

          switch (whatChanged) {
            case "columnsSummons":
              let summonsColumns = this.state.summonsColumns;
              summonsColumns.splice(index, 1);
              this.setState({ summonsColumns: summonsColumns });
              break;

            case "columnsSheriff":
              let sheriffColumns = this.state.sheriffColumns;
              sheriffColumns.splice(index, 1);
              this.setState({ sheriffColumns: sheriffColumns });
              break;

            case "columnsJudge":
              let judgeColumns = this.state.judgeColumns;
              judgeColumns.splice(index, 1);
              this.setState({ judgeColumns: judgeColumns });
              break;

            case "columnsOther":
              let otherColumns = this.state.otherColumns;
              otherColumns.splice(index, 1);
              this.setState({ otherColumns: otherColumns });
              break;
            default:

          }

        } else {

          switch (whatChanged) {
            case "columnsSummons":
              this.setState(state => state.summonsColumns[index].name = value);
              break;

            case "columnsSheriff":
              this.setState(state => state.sheriffColumns[index].name = value);
              break;

            case "columnsJudge":
              this.setState(state => state.judgeColumns[index].name = value);
              break;

            case "columnsOther":
              this.setState(state => state.otherColumns[index].name = value);
              break;
            default:

          }
        }

    }

  }

  onSaveEntry = async (what) => {
    let response = "";
    let updateObj = "";
    const newUsers = this.state.newUsers;

    switch (what) {
      case "clients":
        const clients = this.state.clients;
        updateObj = {
          entries: clients,
          itemId: "clients",
          itemType : "clients"
        };

        this.setState({ clientsChanged: false });

        break;

      case "columns":
        let columns = [];
        let itemId = "";
        if (this.state.showColSummons) {
          columns = this.state.summonsColumns;
          itemId = "summonsColumns";
        } else if (this.state.showColSheriff) {
          columns = this.state.sheriffColumns;
          itemId = "sheriffColumns";
        } else if (this.state.showColJudge) {
          columns = this.state.judgeColumns;
          itemId = "judgementColumns";
        } else {
          columns = this.state.otherColumns;
          itemId = "otherColumns";
        }

        updateObj = {
          entries: columns,
          itemId: itemId,
          itemType : "columns"
        };

        this.setState({ columnsChanged: false });
        break;

      case "courts":
        const courts = this.state.courts;
        updateObj = {
          entries: courts,
          itemId: "courts",
          itemType : "courts"
        };

        this.setState({ courtsChanged: false });
        break;

      case "plaintiffs":
        const plaintiffs = this.state.plaintiffs;
        updateObj = {
          entries: plaintiffs,
          itemId: "plaintiffs",
          itemType : "plaintiffs"
        };

        this.setState({ plaintiffsChanged: false });
        break;

      case "process":
        const process = this.state.process;
        updateObj = {
          entries: process,
          itemId: "process",
          itemType : "process"
        };

        this.setState({ processChanged: false });
        break;

      case "sheriffs":
        const sheriffs = this.state.sheriffs;
        updateObj = {
          entries: sheriffs,
          itemId: "sheriffs",
          itemType : "sheriffs"
        };

        this.setState({ sheriffsChanged: false });
        break;

      case "statuses":
        const statuses = this.state.statuses;
        updateObj = {
          entries: statuses,
          itemId: "statuses",
          itemType : "statuses"
        };

        this.setState({ statusesChanged: false });
        break;

      case "users":
        if (newUsers.length > 0) {
          newUsers.forEach((item, i) => {
            updateObj = item;
          });
        }
        this.setState({ usersChanged: false });
        break;
      default:

    }

    if (what === "users") {

      this.saveUserEdits(updateObj);

    } else {
      response = await backendApi.updateItem({
        type: 'updateColumns',
        entry: updateObj
      },"item");

      if (response.status === 200 || what === "users") {
        this.notifySave();
        await backendApi.getColumnsData();

        setTimeout(() => {
          this.getAllConfigData();
        }, 2500);

      } else {
        this.notifyError();
      }
    }
  }

  saveUserEdits = async (updateObj) => {
    let response = null;
    const newUsers = this.state.newUsers;
    // const currentUsers = this.state.users;
    try {
      if (newUsers.length > 0) {
        const newUser = await Auth.signUp({ username: updateObj.email, password: updateObj.password });
        updateObj.itemId = newUser.userSub;
        console.log("new user log", newUser);

        response = await backendApi.createUserInDB({
          type: 'newProfile',
          entry: updateObj
        });

        if (response.status === 200) {
          this.notifySave();
          await backendApi.getColumnsData();

          this.setState({ newUsers: [] });
          setTimeout(() => {
            this.getAllConfigData();
          }, 2500);

        } else {
          this.notifyError();
        }

      } else {

        // for (var i = 0; i < currentUsers.length; i++) {
        //   response = await backendApi.updateItem({
        //     type: 'updateUsers',
        //     entry: currentUsers[i]
        //   },"item");
        // }
        //
        // if (currentUsers.length === i) {
        //   if (response.status === 200) {
        //     this.notifySave();
        //     await backendApi.getColumnsData();
        //
        //     setTimeout(() => {
        //       this.getAllConfigData();
        //     }, 2500);
        //
        //   } else {
        //     this.notifyError();
        //   }
        // }

      }
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }

  render() {
    return(
      <div className="Management">

        <ToastContainer enableMultiContainer containerId={'success'} transition={Flip} position={toast.POSITION.TOP_CENTER} />
        <ToastContainer enableMultiContainer containerId={'error'} transition={Flip} position={toast.POSITION.TOP_CENTER} />

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <div className="man-container">


          <Tab.Container id="left-tabs" defaultActiveKey="clients">
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="flex-column man-view">
                  <Nav.Item>
                    <Nav.Link eventKey="clients" onClick={e => this.setState({ showClients: true })}>Clients</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="courts" onClick={e => this.setState({ showCourts: true })}>Courts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="plaintiff" onClick={e => this.setState({ showPlaintiffs: true })}>Plaintiffs</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="process" onClick={e => this.setState({ showProcess: true })}>Process Types</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sheriffs" onClick={e => this.setState({ showSheriffs: true })}>Sheriffs</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="statuses" onClick={e => this.setState({ showStatuses: true })}>Statuses</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="man-columns-nav">
                    <Dropdown>
                      <Dropdown.Toggle>
                        Columns
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Nav.Link eventKey="columns" onClick={e => this.setState({
                              showColumns: true, showColSummons: true, showColSheriff: false, showColJudge: false, showColOther: false
                            })}>Summons</Nav.Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Nav.Link eventKey="columns" onClick={e => this.setState({
                              showColumns: true, showColSheriff: true, showColSummons: false, showColJudge: false, showColOther: false
                            })}>Sheriff Columns</Nav.Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Nav.Link eventKey="columns" onClick={e => this.setState({
                              showColumns: true, showColSummons: false, showColSheriff: false, showColJudge: true, showColOther: false
                            })}>Judgement</Nav.Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Nav.Link eventKey="columns" onClick={e => this.setState({
                              showColumns: true, showColSummons: false, showColSheriff: false, showColJudge: false, showColOther: true
                            })}>Other</Nav.Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="users" onClick={e => this.setState({ showUsers: true })}>Users</Nav.Link>
                  </Nav.Item>

                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="clients">
                    {this.state.showClients ?
                      <Clients state={this.state} changed={this.state.clientsChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="courts">
                    {this.state.showCourts ?
                      <Courts state={this.state} changed={this.state.courtsChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="plaintiff">
                    {this.state.showPlaintiffs ?
                      <Plaintiffs state={this.state} changed={this.state.plaintiffsChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="process">
                    {this.state.showProcess ?
                      <Process state={this.state} changed={this.state.processChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="sheriffs">
                    {this.state.showSheriffs ?
                      <Sheriffs state={this.state} changed={this.state.sheriffsChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="statuses">
                    {this.state.showStatuses ?
                      <Statuses state={this.state} changed={this.state.statusesChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="columns">
                    {this.state.showColumns ?
                      <Columns state={this.state} changed={this.state.columnsChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} />
                    : ""}
                  </Tab.Pane>

                  <Tab.Pane eventKey="users">
                    {this.state.showUsers ?
                      <Users state={this.state} changed={this.state.usersChanged} onChanged={this.onChanged}
                        onAddEntry={this.onAddEntry} onSaveEntry={this.onSaveEntry} sendUserDetails={this.sendUserDetails} />
                    : ""}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </div>
      </div>
    )
  }
}
