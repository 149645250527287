import { API, Auth } from 'aws-amplify';

export async function getAuthUser() {

  return Auth.currentAuthenticatedUser({
    bypassCache: false
  });
}

export async function getUserData() {
  var user = await getAuthUser();
  const idToSend = "user," + user.username;
  const data = await getData(idToSend);
  localStorage.setItem('myData', JSON.stringify(data));
}

export function getData(id) {
  return API.get('profast', '/getData/' + id);
}

export function createUserInDB(profile) {
  return API.post('profast', '/new', {
    body: profile
  });
}

/**
 * CREATE NEW SUMMONS
 */

 export function createNewItem(item) {
   return API.post("profast", "/new", {
     body: item
   });
 }

export async function getColumnsData() {

  const config = [{
    id: "columns,clients"
  }, {
    id: "columns,courts"
  }, {
    id: "columns,process"
  }, {
    id: "columns,plaintiffs"
  }, {
    id: "columns,sheriffs"
  }, {
    id: "columns,statuses"
  }, {
    id: "columns,summonsColumns"
  }, {
    id: "columns,sheriffColumns"
  }, {
    id: "columns,judgeColumns"
  }, {
    id: "columns,otherColumns"
  }];

  config.forEach((item) => {
    getColumnsConfigData(item.id).then(data => {
      const itemToSave = item.id.split(",")[1];

      data.entries.sort((a, b) => {
        return a.id - b.id;
      });

      localStorage.setItem(itemToSave, JSON.stringify(data.entries));
    });
  });
}

export function getColumnsConfigData(id) {
  return API.get('profast', '/getData/' + id);
}

export function getAllUsers() {
  return API.get('profast', '/listData/0,users');
}

export function getReference(refNumber) {
  return API.get('profast', '/listData/0,processRef,' + refNumber);
}

export function getReportList(report, client, timeframe, lastKeyRef) {
  const myData = JSON.parse(localStorage.getItem('myData'));
  const itemId = myData.itemId;
  return API.get('profast', '/listData/0,' + report + ',' + client + ',' + timeframe + "," + itemId);
}

export function getReportListSummons(report, client, timeframe, lastKeyRef) {
  lastKeyRef = lastKeyRef ? encodeURIComponent(lastKeyRef) : "null";
  return API.get('profast', `/listData/0,${report},${client},${timeframe},${lastKeyRef}`);
}

export function getUpdateList(list) {
  return API.get('profast', '/listData/0,processItem,' + list);
}

export function getAttachmentList(client) {
  const myData = JSON.parse(localStorage.getItem('myData'));
  const itemId = myData.itemId;
  return API.get('profast', '/listData/0,quickview,' + client + "," + itemId);
}

export function getAttachmentListDateSelected(client, date) {
  const myData = JSON.parse(localStorage.getItem('myData'));
  const itemId = myData.itemId;
  return API.get('profast', '/listData/0,quickview,' + client + "," + itemId + "," + date);
}

export function getSearchRefCaseList(search) {
  const myData = JSON.parse(localStorage.getItem('myData'));
  const itemId = myData.itemId;
  return API.get('profast', '/listData/0,searchCase,' + search + "," + itemId);
}

export function updateItem(data, id) {
  return API.put('profast', '/update/' + id, {
    body: data
  });
}

export function deleteItem(id, bar) {

  if (id.includes("/")) {
    id = id.replace(/\//g, 'slash');
  }

  return API.del('profast', '/delete/' + id + "," + bar);
}

export async function sendEmail(row) {
  await sendMail({
    toAddress: row.email,
    content: {
      user: row
    }
  });
}

export function sendMail(payload) {
  return API.post('profast', '/email/send', {
    body: payload
  });
}
