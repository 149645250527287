import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { ToastContainer, toast, Flip } from 'react-toastify';
import NavTabs from "../home/NavTabs";
import DatePicker from "react-datepicker";
import { FiMinusCircle, FiSave } from "react-icons/fi";
import { AiOutlineScan } from "react-icons/ai";
import { FaUndo } from "react-icons/fa";
import moment from 'moment';
import NotesModal from "../home/NotesModal";
import BarcodeReader from 'react-barcode-reader';

import "./Update.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

import * as backendApi from '../../services/api';
// import * as struct from "./itemStructures";

export default class UpdateOther extends Component {

  notifySave = () => toast.success('Other Saved', {delay: 0, containerId: 'success', autoClose: 2000});
  notifyError = () => toast.error('Error during save', {delay: 0, containerId: 'error', autoClose: 2000});


  constructor(props) {
    super(props);

    this.state = {
      admin: false,
      columns: [],
      updateOther: [],
      scanShow: false,
      scannedCodes: [],
      scannedCode: "nothing scanned",
      scannedObj: {},
      notesShow: false,
      noteText: "",
      notesTable: false,
      notes: []
    };
  }

  componentDidMount() {
    const clients = JSON.parse(localStorage.getItem('clients'));
    const courts = JSON.parse(localStorage.getItem('courts'));
    const process = JSON.parse(localStorage.getItem('process'));
    const plaintiffs = JSON.parse(localStorage.getItem('plaintiffs'));
    const sheriffs = JSON.parse(localStorage.getItem('sheriffs'));
    const columns = JSON.parse(localStorage.getItem('otherColumns'));
    const status = JSON.parse(localStorage.getItem('statuses'));

    columns.forEach((item, i) => {
      if (item.name === "Barcode") {
        item.active = false;
      }
    });

    this.setState({
      columns: columns,
      clients: clients,
      courts: courts,
      process: process,
      plaintiffs: plaintiffs,
      sheriffs: sheriffs,
      status: status
    });

  }

  showNotesTable = () => {
    this.setState({ notesTable: true });
  }

  setCurrentRow = (tableIndex) => {
    this.setState({ notesShow: true, notes: this.state.updateOther[tableIndex].otherItem[0].notes, currentIndex: tableIndex });
  }

  getNoteText = (event) => {
    this.setState({ noteText: event.target.value })
  }

  onModalClose = () => {
    this.setState({ notesShow: false, notesTable: false})
  }

  addNewNote = () => {
    const index = this.state.currentIndex;
    const myData = JSON.parse(localStorage.getItem('myData'));
    let updateEntries = this.state.updateOther;
    const noteText = this.state.noteText;

    updateEntries[index].otherItem[0].notes.push({
      createdOn: moment().format("DD/MM/YYYY"),
      createdBy: myData.email,
      note: noteText
    });

    this.setState({
      updateOther: updateEntries,
      noteText: "",
      notesShow: false,
      notesTable: false
    });
  }

  removeEntry = (index) => {
    let updateEntries = this.state.updateOther;
    let scannedObj = this.state.scannedObj;
    const codeId = updateEntries[index];

    delete scannedObj[codeId.barcode];
    updateEntries.splice(index, 1);

    this.setState({ updateSheriff: updateEntries, scannedObj: scannedObj });
  }

  handleKeyPress = (event) => {
    const data = event.target.value;
    let scannedCodes = this.state.scannedCodes;
    let scannedObj = this.state.scannedObj;

    if(event.key === 'Enter'){
      if (!(data in scannedObj)) {
        scannedCodes.push(data);
        scannedObj[data] = 1;
        this.setState({ scannedCodes: scannedCodes, scannedObj: scannedObj });
      }

      event.target.value = "";
    }

  }

  scanBarcode = (data) => {
    let scannedCodes = this.state.scannedCodes;
    let scannedObj = this.state.scannedObj;
    let loadSingle = false;

    data = data.toString();
    if (data.substr(0,1) === "0") {
      data = "0" + data;
    }

    if (!(data in scannedObj)) {
      scannedCodes.push(data);
      scannedObj[data] = 1;
      this.setState({ scannedCodes: scannedCodes, scannedObj: scannedObj });
      loadSingle = true;
    }

    if (!this.state.scanShow && loadSingle) {
      this.fetchScannedData();
    }

  }

  handleScanError(err){
    alert(err);
  }

  fetchScannedData = async () => {

    const stringToSend = JSON.stringify(this.state.scannedObj);
    const updateItems = await backendApi.getUpdateList(stringToSend);
    let storedItems = this.state.updateOther;

    for (var i = 0; i < updateItems.length; i++) {
      const data = updateItems[i][0];

      data.otherItem[0].toClient = data.otherItem[0].toClient !== "" && data.otherItem[0].toClient !== null ? data.otherItem[0].toClient : null;
      data.otherItem[0].statusDate = data.otherItem[0].statusDate !== "" && data.otherItem[0].statusDate !== null ? data.otherItem[0].statusDate : null;

      if (data.otherItem[0].toSheriff) {
        data.otherItem[0].toSheriff = data.otherItem[0].toSheriff !== "" && data.otherItem[0].toSheriff !== null ? data.otherItem[0].toSheriff : null;
      } else {
        data.otherItem[0].toSheriff = null;
      }
      storedItems.push(data);
    }

    this.setState({
      scanShow: false,
      scannedCodes: [],
      updateSummons: storedItems,
      error: ""
    });

  }

  updateDate = (what, index, date) => {
    date = moment(date).format("DD/MM/YYYY");
    this.setState(state => state.updateOther[index].otherItem[0][what] = date);
  }

  handleChangeRaw(what, index, value) {
    if (what === "client" || what === "batchNumber" || what === "defendant") {
      this.setState(state => state.updateOther[index][what] = value);
    } else {

      if (what === "caseNumber") {
        this.setState(state => state.updateOther[index].otherItem[0].caseChanged = true);
        this.setState(state => state.updateOther[index].otherItem[0][what] = value);
      } else {
        this.setState(state => state.updateOther[index].otherItem[0][what] = "");
      }
    }
  }

  saveUpdates = () => {
    this.props.loading(true);
    const myData = JSON.parse(localStorage.getItem('myData'));
    let updates = this.state.updateOther;
    let failedEntries = [];

    updates.forEach(async (item, i) => {
      item.isOther = true;

      item.otherItem[0].updatedBy = myData.email;
      item.otherItem[0].updateDate = moment().format("DD/MM/YYYY");

      if (item.otherItem[0].createdOn === "" || item.otherItem[0].createdOn === null) {
        item.otherItem[0].createdOn = item.otherItem[0].updateDate;
        item.otherItem[0].createdBy = myData.email;
      }

      if (item.otherItem[0].caseChanged) {
        item.caseNumber = item.otherItem[0].caseNumber;
        delete item.otherItem[0].caseChanged;
      }

      try {
        const response = await backendApi.updateItem({
          type: 'updateProcess',
          entry: item
        },'item');

        if (response.status === 200) {
          this.notifySave();
          this.props.loading(false);
        } else {
          this.notifyError();
          failedEntries.push(item);
          this.props.loading(false);
        }
      } catch (e) {
        this.props.loading(false);
        failedEntries.push(item);
        this.notifyError();
      }
    });

    this.setState({ failed: failedEntries });
  }

  render() {
    return (
      <div className="Summons">

        <ToastContainer enableMultiContainer containerId={'success'} transition={Flip} position={toast.POSITION.TOP_CENTER} />
        <ToastContainer enableMultiContainer containerId={'error'} transition={Flip} position={toast.POSITION.TOP_CENTER} />

        <NavTabs className="nav-tabs" rootProps={this.props.history} auth={this.props.roles}/>

        <NotesModal onSaveNote={this.addNewNote} showNotesTable={this.showNotesTable} notes={this.state.notes}
          getNoteText={this.getNoteText} onModalClose={this.onModalClose} state={this.state} />

        <BarcodeReader onError={this.handleScanError} onScan={this.scanBarcode} />

        <div className="table-container">
          <div className="other-heading">
            <div>
              <h3>UPDATE OTHER</h3>
              <AiOutlineScan className="ml-3 mb-2" style={{color: "#19A979", fontSize: "2rem"}} onClick={() => this.setState({ scanShow: true })} />

              <Modal show={this.state.scanShow} onHide={() => this.setState({ scanShow: false })}>
                <Modal.Header closeButton>
                  <Modal.Title>Scan Barcodes</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <Form.Label>Barcode</Form.Label>
                  <Form.Control id="note" type="text" onKeyPress={this.handleKeyPress} />

                  <Table striped bordered size="sm" responsive="sm">
                    <thead>
                    </thead>
                    <tbody>

                      {this.state.scannedCodes.map((code, index) => (
                        <tr key={index}>

                          <td>{code}</td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>

                </Modal.Body>
                <Modal.Footer>
                  <Badge variant="warning" style={{ fontSize: "1rem", display: this.state.error !== '' ? '' : 'none' }}>
                    {this.state.error}
                  </Badge>
                  <Button variant="primary" onClick={this.fetchScannedData}>
                    Process
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            <FaUndo className="undo-update-button" style={{ textAlign: "center", display: this.state.updateOther.length > 0 ? '' : 'none' }}
              onClick={() => this.setState({ updateOther: [], scannedObj: {} })} />
            <FiSave className="save-update-button" style={{ textAlign: "center", display: this.state.updateOther.length > 0 ? '' : 'none' }}
              onClick={this.saveUpdates} />
          </div>

          {this.state.updateOther.length > 0 ?

            <Table striped bordered hover size="sm" responsive="sm" className="update-table other-update-table">
            <thead>
              <tr>
                {this.state.columns.map((column, index) => (
                  <th key={index} style={{ display: column.active ? '' : 'none' }}>{column.name}</th>
                ))}
                <th style={{ textAlign: "center", display: this.state.updateOther.length > 0 ? '' : 'none' }}>Remove</th>
              </tr>
            </thead>
            <tbody>

              {this.state.updateOther.map((row, index) => (
                <tr key={index}>

                  <td>

                    <Form.Control as="select" custom value={row.client}
                      onChange={e => this.handleChangeRaw("client", index, e.target.value)}>

                      {this.state.clients.map((client, index) => (
                        <option key={index}>{client.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>
                    <Form.Control as="select" custom value={row.otherItem[0].process}
                      onChange={e => this.setState(state => state.updateOther[index].otherItem[0].process = e.target.value)}>

                      {this.state.process.map((process, index) => (
                        <option key={index}>{process.name}</option>
                      ))}

                    </Form.Control>
                  </td>

                  <td>

                    <Form.Control as="select" custom value={row.otherItem[0].court}
                      onChange={e => this.setState(state => state.updateOther[index].otherItem[0].court = e.target.value)}>

                      {this.state.courts.map((court, index) => (
                        <option key={index}>{court.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>

                    <Form.Control as="select" custom value={row.otherItem[0].sheriff}
                      onChange={e => this.setState(state => state.updateOther[index].otherItem[0].sheriff = e.target.value)}>

                      {this.state.sheriffs.map((sheriff, index) => (
                        <option key={index}>{sheriff.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>

                    <Form.Control id="defendant" type="text" value={row.defendant}
                      onChange={e => this.handleChangeRaw("defendant", index, e.target.value)} />

                  </td>

                  <td>

                    <Form.Control id="refNumber" type="text" value={row.refNumber} />

                  </td>

                  <td>

                    <Form.Control id="caseNumber" type="text" value={row.otherItem[0].caseNumber}
                      onChange={e => this.handleChangeRaw("caseNumber", index, e.target.value)} />

                  </td>

                  <td>

                    <Form.Control as="select" custom value={row.otherItem[0].otherStatus}
                      onChange={e => this.setState(state => state.updateOther[index].otherItem[0].otherStatus = e.target.value)}>

                      {this.state.status.map((status, index) => (
                        <option key={index}>{status.name}</option>
                      ))}

                    </Form.Control>

                  </td>

                  <td>

                    <DatePicker id="toSheriff" dateFormat="dd/MM/yyyy"
                      onChangeRaw={(event) => this.handleChangeRaw("toSheriff", index)}
                      onChange={date => this.updateDate("toSheriff", index, date)} value={row.otherItem[0].toSheriff} />

                  </td>

                  <td>

                    <DatePicker id="statusDate" dateFormat="dd/MM/yyyy"
                      onChangeRaw={(event) => this.handleChangeRaw("statusDate", index)}
                      onChange={date => this.updateDate("statusDate", index, date)} value={row.otherItem[0].statusDate} />

                  </td>

                  <td>

                    <Form.Control id="batchNumber" type="text" value={row.batchNumber}
                      onChange={e => this.handleChangeRaw("batchNumber", index, e.target.value)} />

                  </td>

                  <td style={{textAlign: "center"}}>

                    <Button variant="light" onClick={() => this.setCurrentRow(index)}>
                      Notes ({row.otherItem[0].notes.length})
                    </Button>

                  </td>

                  <td>

                    <DatePicker id="toClient" dateFormat="dd/MM/yyyy"
                      onChangeRaw={(event) => this.handleChangeRaw("toClient", index)}
                      onChange={date => this.updateDate("toClient", index, date)} value={row.otherItem[0].toClient} />

                  </td>

                  <td style={{textAlign: "center", color: "#dc0d0e", fontSize: "1.5rem"}}>

                    <FiMinusCircle style={{fontSize: "1.5rem", cursor: "pointer"}} className="mt-1" onClick={event => this.removeEntry(index)}/>

                  </td>

                </tr>
              ))}
            </tbody>
          </Table>

          : "" }
        </div>
      </div>
    );
  }
}
