export function newSummons() {

  return {
    client: "",
    process: "",
    court: "",
    plaintiff: "",
    defendant: "",
    batchNumber: "",
    refNumber: "",
    caseNumber: "",
    issueDate: "",
    sheriff: "",
    toSheriff: "",
    toClient: "",
    shipToCourt: "",
    shipFromCourt: "",
    notes: [],
    savedFiles: [],
    barcode: "Generate",
    disabled: true
  }

}

export function newSheriff(newEntry) {

  return {
    client: "",
    refNumber: "",
    caseNumber: "",
    sheriff: "",
    fromSheriff: "",
    batchNumber: "",
    toClient: "",
    notes: [],
    barcode: "Generate",
    disabled: true
  }

}

export function newJudge(newEntry) {

  return {
    client: "",
    refNumber: "",
    plaintiff: "",
    defendant: "",
    judgeProcess: "",
    court: "",
    batchNumber: "",
    caseNumber: "",
    dateLodged: "",
    otherStatus: "",
    statusDate: "",
    toClient: "",
    notes: [],
    barcode: "Generate",
    disabled: true
  }

}

export function newOther(newEntry) {

  return {
    client: "",
    process: "",
    court: "",
    sheriff: "",
    refNumber: "",
    caseNumber: "",
    otherStatus: "",
    statusDate: "",
    notes: [],
    batchNumber: "",
    toClient: "",
    barcode: "Generate",
    disabled: true
  }

}

// WHEN CREATING NEW ENTRIES

export function newAddSummons() {

  return {
    process: "",
    caseNumber: "",
    court: "",
    plaintiff: "",
    defendant: "",
    issueDate: "",
    sheriff: "",
    toSheriff: "",
    toClient: "",
    notes: [],
    savedFiles: [],
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updateDate: "",
    shipToCourt: "",
    shipFromCourt: ""
  }

}

export function newAddSheriff(newEntry) {

  return {
    sheriff: newEntry.sheriff ,
    fromSheriff: "",
    toClient: "",
    notes: [],
    createdBy: "",
    caseNumber: "",
    createdOn: "",
    updatedBy: "",
    updateDate: ""
  }

}

export function newAddJudge(newEntry, fromSummons) {
  var obj = {};

  if (fromSummons) {
    obj = {
      plaintiff: newEntry.plaintiff || "",
      defendant: "",
      judgeProcess: "",
      court: newEntry.court || "",
      dateLodged: "",
      caseNumber: "",
      otherStatus: "",
      statusDate: "",
      toClient: "",
      notes: [],
      createdBy: "",
      createdOn: "",
      updatedBy: "",
      updateDate: ""
    }
  } else {
    obj = {
      plaintiff: "",
      defendant: "",
      judgeProcess: "",
      court: "",
      dateLodged: "",
      caseNumber: "",
      otherStatus: "",
      statusDate: "",
      toClient: "",
      notes: [],
      createdBy: "",
      createdOn: "",
      updatedBy: "",
      updateDate: ""
    }
  }

  return obj;

}

export function newAddOther(newEntry) {

  return {
    process: "",
    court: "",
    sheriff: "",
    caseNumber: "",
    otherStatus: "",
    statusDate: "",
    notes: [],
    toClient: "",
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updateDate: ""
  }

}
